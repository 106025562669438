import { CreateZone } from "../types";
import { Icon } from "@belray-ventec/geslub-platform-react-ui";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  Box,
} from "@chakra-ui/react";
import { useLubricantList } from "core/lubricantes/hook";
import { useZoneTypeList } from "core/zoneTypes/hooks";
import { useEffect, useState } from "react";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";

export interface ZoneFormProps {
  onSubmit: (d: CreateZone, stopLoading: () => void) => void;
  initValues?: Partial<CreateZone> | undefined;
}

export default function ZoneForm({ onSubmit, initValues }: ZoneFormProps) {
  const [formData, setFormData] = useState<CreateZone>({
    name: initValues?.name ?? "",
    description: initValues?.description ?? "",
    scheduledActInalienableHolydays:
      initValues?.scheduledActInalienableHolydays ?? true,
    scheduledActWaivableHolydays:
      initValues?.scheduledActWaivableHolydays ?? true,
    scheduledWeekendActivity: initValues?.scheduledWeekendActivity ?? true,
    validateSchedActByNfc: initValues?.validateSchedActByNfc ?? true,
    type: initValues?.type || { id: "", name: "" },
    enabled: initValues?.enabled ?? true,
    sectorLevels: initValues?.sectorLevels ?? [""],
    equipmentInstanceLevels: initValues?.equipmentInstanceLevels ?? [""],
    lubricants: initValues?.lubricants ?? [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const { zoneTypeList } = useZoneTypeList();
  const { lubricantList } = useLubricantList();

  const zoneTypeFiltered = zoneTypeList.filter((item) => item.enabled);
  const lubricantOptions = lubricantList.map((lub) => ({
    value: lub.id,
    label: lub.name,
  }));

  const initialLubricantes = lubricantOptions.filter((lub) =>
    initValues?.lubricants?.includes(lub.value)
  );

  return (
    <form
      onSubmit={(e) => {
        setIsLoading(true);
        e.preventDefault();
        onSubmit(formData, () => setIsLoading(false));
      }}
    >
      <Box display="flex" gap="1rem" flexDirection="column">
        <FormControl isRequired>
          <FormLabel htmlFor="name">Nombre</FormLabel>
          <Input
            placeholder="Nombre de la zona"
            autoComplete="off"
            onChange={(e) =>
              setFormData({
                ...formData,
                name: e.currentTarget.value,
              })
            }
            value={formData.name}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="description">Descripción</FormLabel>
          <Input
            placeholder="Descripción de la zona"
            name="description"
            autoComplete="off"
            onChange={(e) =>
              setFormData({ ...formData, description: e.currentTarget.value })
            }
            value={formData.description}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="type">Tipo</FormLabel>
          <Select
            options={zoneTypeFiltered.map((item) => {
              const option = { label: item.name, value: item.id };
              return option;
            })}
            onChange={(e) =>
              setFormData({
                ...formData,
                type: {
                  id: String(e?.value),
                  name: e?.label ?? "",
                },
              })
            }
            defaultValue={{
              label: formData.type.name ?? "",
              value: formData.type.id ?? "",
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Selección de lubricantes</FormLabel>
          <Select
            options={lubricantOptions}
            placeholder="Seleccione los lubricantes"
            onChange={(e) => {
              setFormData({
                ...formData,
                lubricants: e.map((item) => String(item.value)),
              });
            }}
            defaultValue={initialLubricantes}
            isMulti
          />
        </FormControl>
        <Box display="flex" alignItems="center" gap="0.5rem">
          <input
            type="checkbox"
            onChange={(e) =>
              setFormData({
                ...formData,
                scheduledWeekendActivity: e.currentTarget.checked,
              })
            }
            defaultChecked={formData.scheduledWeekendActivity}
          />
          <Text fontSize="lg"> La zona opera los fin de semanas</Text>
        </Box>
        <Box display="flex" alignItems="center" gap="0.5rem">
          <input
            type="checkbox"
            onChange={(e) =>
              setFormData({
                ...formData,
                scheduledActInalienableHolydays: e.currentTarget.checked,
              })
            }
            defaultChecked={formData.scheduledActInalienableHolydays}
          />
          <Text fontSize="lg">La zona opera los feriados irrenunciables</Text>
        </Box>
        <Box display="flex" alignItems="center" gap="0.5rem">
          <input
            type="checkbox"
            onChange={(e) =>
              setFormData({
                ...formData,
                scheduledActWaivableHolydays: e.currentTarget.checked,
              })
            }
            defaultChecked={formData.scheduledActWaivableHolydays}
          />
          <Text fontSize="lg">
            Laz zona opera los días feriados renunciables
          </Text>
        </Box>
        <Box display="flex" alignItems="center" gap="0.5rem">
          <input
            type="checkbox"
            onChange={(e) =>
              setFormData({
                ...formData,
                validateSchedActByNfc: e.currentTarget.checked,
              })
            }
            defaultChecked={formData.validateSchedActByNfc}
          />
          <Text fontSize="lg">
            Permitir la validación de actividades realizadas mediante NFC
          </Text>
        </Box>
        <Box display="flex" alignItems="center" gap="0.5rem">
          <input
            type="checkbox"
            id="enabled"
            onChange={(e) =>
              setFormData({
                ...formData,
                enabled: e.currentTarget.checked,
              })
            }
            defaultChecked={formData.enabled}
          />
          <Text fontSize="lg">La zona se encuentra habilitada</Text>
        </Box>
        <Accordion allowMultiple>
          <AccordionItem>
            <AccordionButton>
              <Text fontSize="lg">Niveles de sectores</Text>
            </AccordionButton>
            <AccordionPanel>
              <Levels
                values={formData.sectorLevels}
                onChange={(niveles_sectores) => {
                  setFormData({ ...formData, sectorLevels: niveles_sectores });
                }}
                disableEdit={initValues?.sectorLevels ? true : false}
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Text fontSize="md">Niveles de instancias de equipos</Text>
            </AccordionButton>
            <AccordionPanel>
              <Levels
                values={formData.equipmentInstanceLevels}
                onChange={(niveles_equipos) => {
                  setFormData({
                    ...formData,
                    equipmentInstanceLevels: niveles_equipos,
                  });
                }}
                disableEdit={initValues?.equipmentInstanceLevels ? true : false}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Button variant="primary" type="submit" isLoading={isLoading}>
          Aceptar
        </Button>
      </Box>
    </form>
  );
}

function Levels({
  values,
  onChange,
  disableEdit,
}: {
  values: CreateZone["sectorLevels"];
  onChange: (d: CreateZone["sectorLevels"]) => void;
  disableEdit?: boolean;
}) {
  const [formValues, setformValues] = useState(() =>
    values.map((value) => ({ name: value, id: uuidv4() }))
  );

  useEffect(() => {
    onChange(formValues.map((item) => item.name));
  }, [formValues]);

  const handleAdd = () => {
    setformValues([...formValues, { name: "", id: uuidv4() }]);
  };

  const handleChange = (newItem: { id: string; name: string }) => {
    const newValues = formValues.map((value) =>
      newItem.id === value.id ? { ...value, name: newItem.name } : value
    );
    setformValues(newValues);
  };

  const handleRemove = (idx: number) => {
    const levelsFiltered = values.filter((_, i) => i !== idx);
    levelsFiltered.map((__, i) => i === idx);
    setformValues(formValues.filter((__, index) => idx !== index));
  };

  const getLevelNameByIndex = (idx: number): string =>
    idx === 0 ? "base" : String(idx);

  return (
    <Box marginBottom="4px">
      <Button
        variant="primary"
        disabled={disableEdit}
        style={disableEdit ? { display: "none" } : undefined}
        leftIcon={<Icon icon="Add" size={15} color={"#fff"} />}
        onClick={handleAdd}
      >
        Agregar nivel
      </Button>

      {formValues.map((item, idx) => (
        <Box key={item.id} display="flex" flexDirection="column" gap="0.5rem">
          <FormControl isRequired>
            <FormLabel htmlFor={item.id}>
              Nombre del nivel {getLevelNameByIndex(idx)}
            </FormLabel>
            <Input
              id={item.id}
              name={getLevelNameByIndex(idx)}
              autoComplete="off"
              placeholder="Nombre de nivel"
              value={item.name}
              onChange={(e) =>
                handleChange({ name: e.target.value, id: item.id })
              }
            />
          </FormControl>

          <Button
            disabled={disableEdit}
            style={disableEdit ? { display: "none" } : undefined}
            leftIcon={<Icon icon="Trash" size={15} color={"#fff"} />}
            variant="primary"
            backgroundColor="#e74c3c"
            onClick={() => handleRemove(idx)}
          >
            Eliminar nivel {getLevelNameByIndex(idx)}
          </Button>
        </Box>
      ))}
    </Box>
  );
}
