export const ROUTES = {
  HOME: "/",
  ACCOUNT: "/account",
  USERS: "/users",
  ANNOUNCEMENTS: "/announcements",
  LOGIN: "/login",
  ZONES: "/zones",
  ZONE_TYPES: "/zone-types",
  PASS_RESET: "/password-reset",
  PLATFORMS_ADMIN: "/platform-admin",
  STORE_ADMIN: "/store-admin",
  NO_PLATFORM_AUTH: "/no-platform-auth",
};
