import { useErrorLogger } from "../../utils/error/logError";
import style from "./passReset.module.css";
import { Text, Heading, Box, Alert, AlertIcon } from "@chakra-ui/react";
import ResetForm, { ResetEmailFormProps } from "core/resetPass/forms/resetForm";
import { useResetEmailList } from "core/resetPass/hook";
import { Link } from "react-router-dom";
import BrandLogos from "utils/components/brandLogos";
import { useShowToast } from "utils/hooks/useShowToast";
import { ROUTES } from "utils/routes";

export default function PasswordReset() {
  const { onCreateResetEmail } = useResetEmailList();
  const logError = useErrorLogger();
  const { showToast } = useShowToast();

  const handleCreateResetPassword: ResetEmailFormProps["onSubmit"] = async (
    data,
    stopLoading
  ) => {
    try {
      if (data) {
        await onCreateResetEmail(data);
        showToast(
          "Correo enviado",
          "Se ha enviado un correo a su cuenta",
          "success"
        );
        stopLoading();
      }
    } catch (e) {
      let msg = "Error desconocido";
      if (e instanceof Error) msg = e.message;
      logError(e);
      showToast("Error", msg, "error");
    } finally {
      stopLoading();
    }
  };

  return (
    <Box display="flex" flexDirection="row" minHeight="100vh" minWidth="350px">
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        justifyContent="center"
        alignItems="center"
      >
        <BrandLogos />
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="1rem"
            minWidth="300px"
            maxWidth="600px"
            padding="3.5rem"
            background="white"
            boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"
          >
            <Heading as="h1" size="xl" fontWeight={600} color="#173c5e">
              Geslub Platform
            </Heading>
            <Heading
              size="md"
              as="h2"
              fontWeight={400}
              color="#173c5e"
              marginBottom="0.5rem"
              alignSelf="center"
            >
              Recuperar contraseña
            </Heading>
            <ResetForm onSubmit={handleCreateResetPassword} />
          </Box>
          <Box marginTop="20px">
            <Alert
              mb={5}
              maxWidth={"sm"}
              variant="left-accent"
              status="warning"
            >
              <AlertIcon />
              El correo para recuperar contraseña podria tardar hasta 5 minutos
              en ser enviado.
            </Alert>
            <Link to={ROUTES.LOGIN}>
              <Text textAlign={"center"} fontSize="xl" color="gray">
                Ir a inicio de sesión
              </Text>
            </Link>
          </Box>
          <a href="mailto:support@geslub.com">
            <Text fontSize="sm">support@geslub.com</Text>
          </a>
        </Box>
      </Box>

      <Box
        display={["none", "none", "none", "inherit"]}
        backgroundSize="cover"
        backgroundPosition="center"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="1rem"
        flex="1"
        padding="5rem"
        className={style.background_section_reset}
      >
        <Heading size={"3xl"} as="h2" color="white">
          Lubricantes Especiales
        </Heading>
        <Text textAlign={"center"} fontSize="2xl" color="white">
          Formulaciones dedicadas a aplicaciones de altos requerimientos y
          contextos operacionales
        </Text>
      </Box>
    </Box>
  );
}
