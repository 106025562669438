import { getErrorMessage } from "./getErrorMessage";
import { useToast } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

type Configs = {
  alert?: boolean;
  capture?: boolean;
  title?: string;
};

export const useErrorLogger = () => {
  const toast = useToast();

  const logError = (error: unknown, configs: Configs = {}) => {
    const { alert = true, capture = true, title = "Error" } = configs;

    const errMsg = getErrorMessage(error) || "Un error inesperado ha ocurrido.";

    if (capture) {
      //Aqui podriamos enviar el error a Sentry o lo que sea
      Sentry.withScope((scope) => {
        scope.setTag("component", "useErrorLogger");
        Sentry.captureException(error);
      });
    }

    if (alert) {
      toast({
        position: "top-right",
        title,
        description: errMsg,
        status: "error",
        duration: null,
        isClosable: true,
      });
    }
  };

  return logError;
};
