import { gql } from "@apollo/client";

export const GET_ALL_PLATFORMS = gql`
  query FindAllUserRoles {
    findAllUserRoles {
      id
      label
      platform {
        id
      }
    }
  }
`;
