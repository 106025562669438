import * as API from "./gql";
import { UserRole } from "./types";
import { useQuery } from "@apollo/client";

export const useUserRoles = () => {
  const {
    loading,
    error,
    data: findAllUserRoles = { findAllUserRoles: [] },
  } = useQuery<{ findAllUserRoles: UserRole[] }>(API.GET_ALL_PLATFORMS, {});

  return {
    isLoadingPlatform: loading,
    errorUsePlatform: error,
    userRolesList: findAllUserRoles.findAllUserRoles,
  };
};
