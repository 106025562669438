import { useErrorLogger } from "../../utils/error/logError";
import { isSessionActive } from "@belray-ventec/platform-session";
import {
  Text,
  Heading,
  Box,
  Image,
  Card,
  Link as ChakraLink,
} from "@chakra-ui/react";
import LOGIN_IMAGE from "assets/images/caex.jpg";
import { useAuth } from "core/auth/hooks";
import LoginForm, { LoginFormProps } from "core/session/forms/LoginForm";
import { useState } from "react";
import { FaUser } from "react-icons/fa";
import { useNavigate, Link, useLocation, Navigate } from "react-router-dom";
import BrandLogos from "utils/components/brandLogos";
import { useShowToast } from "utils/hooks/useShowToast";
import { ROUTES } from "utils/routes";

export default function Login() {
  const [isRedictioning, setIsRedictioning] = useState(false);
  const logError = useErrorLogger();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const { showToast } = useShowToast();

  const from = location.state?.from?.pathname || "/";
  const redirect = new URLSearchParams(location.search).get("redirect");

  const redirectUser = () => {
    setIsRedictioning(true);
    if (redirect) window.location.replace(redirect);
    else navigate(from, { replace: true });
  };

  const handleSubmit: LoginFormProps["onSubmit"] = async (
    values,
    setLoading
  ) => {
    try {
      const user = await auth.signin(values);

      if (user) {
        setIsRedictioning(true);
        redirectUser();
      }
    } catch (e) {
      logError(e);
      showToast("Error", JSON.stringify(e), "error");
    } finally {
      setLoading(false);
    }
  };

  if (isRedictioning) {
    return <Box>Redireccionando...</Box>;
  }

  if (isSessionActive()) {
    return <Navigate to={ROUTES.HOME} replace />;
  }

  return (
    <Box display="flex" flexDir="row" minH="100vh" minW="350px">
      <Box
        display="flex"
        flexDir="column"
        flex={1}
        justifyContent="center"
        alignItems="center"
      >
        <BrandLogos />
        <Box
          display="flex"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          flex={1}
        >
          <Card gap="1rem" minW="300px" maxW="600px" padding="3.5rem">
            <Heading fontWeight="600" color="#173c5e" as="h1" size="xl">
              Geslub Platform
            </Heading>
            <LoginForm onSubmit={handleSubmit} />
            <ChakraLink
              _hover={{
                color: "blue.700",
              }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSctbSlOuuk-DPQBSUUSA5GCAXixGgnO9FD8it8Cz-hwfDIN0Q/viewform?usp=sf_link"
              display="flex"
              alignItems="center"
              gap={1}
              justifyContent={["center", "center", "flex-end", "flex-end"]}
              w="100%"
              isExternal
            >
              <FaUser />
              <Text fontSize="sm">Solicitar creación de cuenta</Text>
            </ChakraLink>
          </Card>
          <Box mx={2} display="grid" placeContent="center" marginTop="1rem">
            <Link to={ROUTES.PASS_RESET}>
              <Text textAlign={"center"} fontSize="xl" color="gray">
                Recuperar contraseña
              </Text>
            </Link>
          </Box>
          <Box as="a" href="mailto:support@geslub.com">
            <Text fontSize="xs">support@geslub.com</Text>
          </Box>
        </Box>
      </Box>

      <Box flex={1} display={["none", "none", "none", "inherit"]}>
        <Image
          src={LOGIN_IMAGE}
          alt="Camión Caex"
          objectFit="cover"
          h="100%"
          w="100%"
        />
      </Box>
    </Box>
  );
}
