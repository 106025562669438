import { useAuth } from "../../core/auth/hooks";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { FaLink } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

export default function NoPlatformAuth() {
  const session = useAuth();

  const availablePlatforms = {
    Planning: {
      authorization: session.user?.planningConfig.authorization ?? false,
      link: "https://planning.geslub.com",
    },
    Library: {
      authorization: session.user?.libraryConfig.authorization ?? false,
      link: "https://library.geslub.com",
    },
    Tribolab: {
      authorization: session.user?.tribolabConfig.authorization ?? false,
      link: "https://tribolab.geslub.cl",
    },
    Stocks: {
      authorization: session.user?.stocksConfig.authorization ?? false,
      link: "https://vales.geslub.cl",
    },
    Platform: {
      authorization: session.user?.homeConfig.authorization ?? false,
      link: "https://platform.geslub.com",
    },
  };
  const navigation = useNavigate();
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="1rem"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Heading size="2xl">Sin autorización</Heading>
      <Box>
        <Text fontSize="2xl" align="center">
          Su usuario no tiene autorización para entrar a la plataforma
          solicitada.
        </Text>

        <Text fontSize="xl" align="center">
          Si tienes dudas, consulta con el soporte
          <Text fontSize="lg">support@geslub.com</Text>
        </Text>
      </Box>

      <Box>
        <Alert status="info" variant="left-accent">
          <AlertIcon />
          <AlertDescription>
            Recuerda que tienes acceso a las siguientes Plataformas
          </AlertDescription>
        </Alert>
        <Box my={5}>
          <List spacing={3}>
            {Object.keys(availablePlatforms).map((platform) => {
              if (
                availablePlatforms[platform as keyof typeof availablePlatforms]
                  .authorization
              )
                return (
                  <ListItem key={platform}>
                    <ListIcon as={FaLink} color="blue.700" />
                    <Link
                      to={
                        availablePlatforms[
                          platform as keyof typeof availablePlatforms
                        ].link
                      }
                    >
                      {platform}
                    </Link>
                  </ListItem>
                );
            })}
          </List>
        </Box>
      </Box>

      <Link to="/">
        <Button variant="primary">Ir a Geslub Platform</Button>
      </Link>
      <Button onClick={() => session.signout(() => navigation("/"))}>
        Cerrar Sesion
      </Button>
    </Box>
  );
}
