import { gql } from "@apollo/client";

export const GET_ALL_ZONE_TYPE = gql`
  query FindAllZonesTypes {
    findAllZonesTypes {
      id
      name
      description
      enabled
    }
  }
`;
export const CREATE_ZONE_TYPE = gql`
  mutation CreateZoneType($createZoneType: CreateZoneTypeInput!) {
    createZoneType(createZoneType: $createZoneType) {
      id
      name
      description
    }
  }
`;
export const REMOVE_ZONE_TYPE = gql`
  query DeleteZoneType($deleteZoneTypeId: String!) {
    deleteZoneType(id: $deleteZoneTypeId) {
      id
      name
      description
    }
  }
`;

export const UPDATE_ZONE_TYPE = gql`
  mutation UpdateZoneType($updateZoneTypeInput: UpdateZoneTypeInput!) {
    updateZoneType(updateZoneTypeInput: $updateZoneTypeInput) {
      id
      name
      description
    }
  }
`;

export const DISABLE_ZONE_TYPE = gql`
  mutation UpdateZoneType($updateZoneTypeInput: UpdateZoneTypeInput!) {
    updateZoneType(updateZoneTypeInput: $updateZoneTypeInput) {
      id
      name
      description
    }
  }
`;
