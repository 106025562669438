export const DEFAULT_USER_CONFIGS = {
  planning: {
    id_platform: "6114bd53-3c66-42df-b8ed-6f2293c5c9a0",
    id_role: "a1a13f46-e585-4799-811f-623fab21f209",
    label: "Operario",
    authorization: false,
  },
  platform: {
    id_platform: "0501bedb-8000-410a-af7d-dc1895fe4e20",
    id_role: "9f359b4b-2230-454f-8855-01981dcb27c9",
    label: "Visualizador",
    authorization: true,
  },
  dashboard: {
    id_platform: "3f9ab40f-677e-4bbd-b895-af6392a66f88",
    id_role: "8c6d1f2d-135b-44a4-aebd-f9f00ca31713",
    label: "Visualizador",
    authorization: false,
  },
  datareport: {
    id_platform: "72db7f12-1dea-4a19-9e60-2aa7ad70eae4",
    id_role: "c2eef974-3686-4d71-a65f-c93da2b7a094",
    label: "Visualizador",
    authorization: false,
  },
  tribolab: {
    id_platform: "8fcc3a9e-35cb-4c00-b6d4-c9d4acbb6bab",
    id_role: "2bb7c788-91cd-4ea7-acc6-bff64f2c76fe",
    label: "Visualizador",
    authorization: false,
  },
  stocks: {
    id_platform: "9421aa28-12d9-42b4-a766-01e27f97f6ba",
    id_role: "bdfa1e62-f284-4a7b-81de-34d627b4abe9",
    label: "Operario",
    authorization: false,
  },
  library: {
    id_platform: "2b5e7690-ab0e-4271-9b54-c0e3031ee0fa",
    id_role: "febd1206-7c38-466b-8226-635cc34cace3",
    label: "Usuario",
    authorization: false,
  },
  admin: {
    id_platform: "9103c3d4-7b3f-4149-ad86-22d892713fb7",
    id_role: "39dddddb-c970-4d1b-b6e7-fdd5cca7078e",
    label: "Administrador",
    authorization: false,
  },
  scoutlistening: {
    id_platform: "06e4252f-80ec-40e7-b4a4-ff3c72fe4497",
    id_role: "620b6a12-80e2-4acb-b25f-be4eff33f275",
    authorization: false,
  },
};
