import { SessionStorage } from "@belray-ventec/platform-session";
import { Box } from "@chakra-ui/react";
import { useLogin } from "core/session/hooks";
import { useUser } from "core/users/hooks";
import { UserState } from "core/users/types";
import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMIN_NAVS, PUBLIC_NAVS } from "ui/layout";
import { useErrorLogger } from "utils/error/logError";
import { useShowToast } from "utils/hooks/useShowToast";
import { ROUTES } from "utils/routes";
import { USER_ROLES } from "utils/userRoles";

interface AuthContextType {
  user: UserState | null | undefined;
  signin: (user: {
    email: string;
    password: string;
  }) => Promise<UserState | null | undefined>;
  signout: (callback: VoidFunction) => void;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  signin: () => Promise.resolve(null),
  signout: () => undefined,
});

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const logError = useErrorLogger();
  const [user, setUser] = useState<UserState | null | undefined>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { loginUser } = useLogin();
  const { getUserById } = useUser();
  const { showToast } = useShowToast();

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    try {
      const session = SessionStorage.get();

      if (!session?.authToken || !session?.userId)
        return SessionStorage.remove();

      const user = await getUserById(session.userId);
      if (!user) return SessionStorage.remove();

      setUser(user);
    } catch (e) {
      logError(e);
      SessionStorage.remove();
    } finally {
      setIsLoading(false);
    }
  };

  const signin: AuthContextType["signin"] = async (credentials) => {
    try {
      const res = await loginUser(credentials.email, credentials.password);
      if (!res) {
        showToast("Error", "Credenciales invalidas", "error");
        return null;
      }
      if (!res.token || !res.user) throw new Error("Respuesta sin datos");

      if (!res.user.enabled) throw new Error("Usuario deshabilitado");
      if (!res.user.homeConfig.authorization)
        window.location.href = ROUTES.NO_PLATFORM_AUTH;

      SessionStorage.set({ userId: res.user.id, authToken: res.token });
      setUser(res.user);
      return res.user;
    } catch (e) {
      let msg;
      if (e instanceof Error) msg = e.message;
      showToast("No se pudo iniciar sesión", `${msg}`, "error");
      return null;
    }
  };

  const signout = (callback: VoidFunction) => {
    setUser(null);
    SessionStorage.remove();
    callback();
  };

  const value = { user, isLoading, signin, signout };

  const navigate = useNavigate();
  const location = useLocation();

  const userRole = user?.homeConfig?.role?.id;

  if (isLoading) return <Box fontStyle="italic">Cargando....</Box>;

  if (
    ADMIN_NAVS.map((item) => item[1]).includes(location.pathname) &&
    userRole !== USER_ROLES.ADMINISTRATOR
  ) {
    navigate(PUBLIC_NAVS[0][1]);
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
