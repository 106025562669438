import { initializeApp } from "firebase/app";

export const initFirebaseApp = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyCTaLcjjToCpwVVI4y1wiLnuOR4xv2CNhk",
    authDomain: "gothic-state-330514.firebaseapp.com",
    projectId: "gothic-state-330514",
    storageBucket: "gothic-state-330514.appspot.com",
    messagingSenderId: "107428276961",
    appId: "1:107428276961:web:d3ac375603be9df44665a0",
    measurementId: "G-7G11JPNCY8",
  };

  return initializeApp(firebaseConfig);
};
