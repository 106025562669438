import * as API from "./gpl";
import { UpdateUserApiInput, User, UserForm, UserState } from "./types";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

export const useUserList = () => {
  const {
    loading,
    error,
    data: UserList = { findAllUser: [] },
    refetch,
  } = useQuery<{ findAllUser: User[] }>(API.GET_ALL_USERS);

  const [createUser] = useMutation(API.CREATE_USER, {
    onCompleted: () => refetch(),
  });
  const [updateUser] = useMutation(API.UPDATE_USER, {
    onCompleted: () => refetch(),
  });
  const [removeUser] = useLazyQuery(API.REMOVE_USER, {
    onCompleted: () => refetch(),
  });

  const onCreateUser = async (data: Omit<UserForm, "id">): Promise<void> => {
    const newItem = {
      user: {
        name: data.name,
        lastname: data.lastname,
        rut: data.rut,
        email: data.email,
        zones: data.zones.map((z) => z.id),
        job: data.job,
        phone: data.phone,
      },
      config: {
        adminConfigs: {
          authorization: data.adminConfig.authorization,
          roleId: data.adminConfig.role.id,
        },
        planningConfigs: {
          authorization: data.planningConfig.authorization,
          roleId: data.planningConfig.role.id,
        },
        homeConfigs: {
          authorization: data.homeConfig.authorization,
          roleId: data.homeConfig.role.id,
        },
        datareportConfigs: {
          authorization: data.datareportConfig.authorization,
          roleId: data.datareportConfig.role.id,
        },
        libraryConfigs: {
          authorization: data.libraryConfig.authorization,
          roleId: data.libraryConfig.role.id,
        },
        stocksConfigs: {
          authorization: data.stocksConfig.authorization,
          roleId: data.stocksConfig.role.id,
        },
        dashboardConfigs: {
          authorization: data.dashboardConfig.authorization,
          roleId: data.dashboardConfig.role.id,
        },
        tribolabConfigs: {
          authorization: data.tribolabConfig.authorization,
          roleId: data.tribolabConfig.role.id,
        },
      },
    };
    await createUser({
      variables: {
        createUserInput: newItem,
      },
    });
  };

  const onUpdateUser = async (
    id: User["id"],
    data: UpdateUserApiInput
  ): Promise<void> => {
    const config = {
      adminConfigs: {
        roleId: data.adminConfig?.role.id,
        authorization: data.adminConfig?.authorization,
      },
      planningConfigs: {
        roleId: data.planningConfig?.role.id,
        authorization: data.planningConfig?.authorization,
      },
      homeConfigs: {
        roleId: data.homeConfig?.role.id,
        authorization: data.homeConfig?.authorization,
      },
      dashboardConfigs: {
        roleId: data.dashboardConfig?.role.id,
        authorization: data.dashboardConfig?.authorization,
      },
      datareportConfigs: {
        roleId: data.datareportConfig?.role.id,
        authorization: data.datareportConfig?.authorization,
      },
      libraryConfigs: {
        roleId: data.libraryConfig?.role.id,
        authorization: data.libraryConfig?.authorization,
      },
      stocksConfigs: {
        roleId: data.stocksConfig?.role.id,
        authorization: data.stocksConfig?.authorization,
      },
      tribolabConfigs: {
        roleId: data.tribolabConfig?.role.id,
        authorization: data.tribolabConfig?.authorization,
      },
    };
    delete data.adminConfig;
    delete data.planningConfig;
    delete data.homeConfig;
    delete data.datareportConfig;
    delete data.libraryConfig;
    delete data.stocksConfig;
    delete data.dashboardConfig;
    delete data.tribolabConfig;

    await updateUser({
      variables: {
        updateUserInput: {
          id,
          user: data,
          config,
        },
      },
    });
  };

  const onRemoveUser = async (id: User["id"]): Promise<void> => {
    await removeUser({
      variables: {
        deleteUserId: id,
      },
    });
  };

  return {
    isLoadingUserList: loading,
    errorUserList: error,
    UserList: UserList.findAllUser,
    onCreateUser,
    onUpdateUser,
    onRemoveUser,
  };
};

export const useUser = () => {
  const [getUserByIdApi] = useLazyQuery<
    { findUserById: UserState },
    { findUserByIdId: string }
  >(API.GET_USER_BY_ID);

  const getUserById = async (
    id: string
  ): Promise<UserState | undefined | null> => {
    const user = await getUserByIdApi({
      variables: {
        findUserByIdId: id,
      },
    });

    return user?.data?.findUserById;
  };

  return { getUserById };
};
