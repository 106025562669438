import { useErrorLogger } from "../../utils/error/logError";
import { Icon, Modal } from "@belray-ventec/geslub-platform-react-ui";
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
} from "@chakra-ui/react";
import ZoneForm, { ZoneFormProps } from "core/zones/forms/ZoneForm";
import { useZoneList } from "core/zones/hooks";
import { ZoneApi } from "core/zones/types";
import { useEffect, useState } from "react";
import AdminTable, { AdminTableProps } from "ui/tables";
import { useShowToast } from "utils/hooks/useShowToast";

export default function Zones() {
  const logError = useErrorLogger();
  const [createCompanyModal, setCreateZoneModal] = useState(false);
  const [editZoneModal, setEditZoneModal] = useState<ZoneApi | null>(null);

  const { showToast } = useShowToast();

  const {
    zoneList,
    onCreateZone,
    onUpdateZone,
    isLoadingZoneList,
    onUpdateUserConfig,
  } = useZoneList();

  const [filteredZone, setFilteredZone] = useState(zoneList);

  useEffect(() => {
    setFilteredZone(zoneList);
  }, [zoneList]);

  const handleFilterZone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const filtered = zoneList.filter((zone) =>
      zone.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredZone(filtered);
  };

  const handleCreateZone: ZoneFormProps["onSubmit"] = async (
    data,
    stopLoading
  ) => {
    try {
      await onCreateZone(data);
      stopLoading();
      setCreateZoneModal(false);
      showToast("Zona creada", "Zona creada exitosamente", "success");
    } catch (e) {
      logError(e);

      stopLoading();
    }
  };

  const handleEditZone: ZoneFormProps["onSubmit"] = async (
    data,
    stopLoading
  ) => {
    try {
      if (editZoneModal) {
        await onUpdateZone(editZoneModal.id, data);
        await onUpdateUserConfig(editZoneModal, data);
      }

      stopLoading();
      setEditZoneModal(null);
    } catch (e) {
      logError(e);

      stopLoading();
    }
  };

  const handleDisableZone: AdminTableProps<ZoneApi>["onDisable"] = async (
    data,
    stopLoading
  ) => {
    try {
      await onUpdateZone(data.id, { enabled: false });
    } catch (e) {
      logError(e);
      showToast("Error", "No se pudo deshabilitar la zona", "error");
    } finally {
      stopLoading();
    }
  };

  const handleEnableZone: AdminTableProps<ZoneApi>["onEnable"] = async (
    data
  ) => {
    try {
      await onUpdateZone(data.id, { enabled: true });
    } catch (e) {
      logError(e);
      showToast("Error", "No se ha podido habilitar la zona", "error");
    }
  };

  return (
    <>
      <Box>
        <Flex
          width={"100%"}
          direction={["column", "column", "row", "row"]}
          justifyContent={[
            "center",
            "center",
            "space-between",
            "space-between",
          ]}
          gap={["24px", "24px", "0", "0"]}
        >
          <Button
            leftIcon={<Icon color="#fff" size={15} icon="Add" />}
            variant="primary"
            onClick={() => setCreateZoneModal(true)}
          >
            Crear zona
          </Button>
          <InputGroup w={["100%", "100%", "300px", "300px"]}>
            <InputLeftElement>
              <Icon size={16} color="#a9a9a9" icon="Seeker" />
            </InputLeftElement>
            <Input placeholder="Ingrese zona" onChange={handleFilterZone} />
          </InputGroup>
        </Flex>

        <AdminTable
          data={filteredZone}
          columns={[{ label: "Nombre", getValue: (zone) => zone.name }]}
          getRowKey={(item) => item.id}
          onEdit={(item) => setEditZoneModal(item)}
          caption="Lista de zonas"
          isLoading={isLoadingZoneList}
          onDisable={handleDisableZone}
          onEnable={handleEnableZone}
          getIfRowIsEnabled={(item) => item.enabled}
        />
      </Box>

      <Modal
        title={"Crear zona"}
        isOpen={createCompanyModal}
        onClose={() => setCreateZoneModal(false)}
        hiddenFooter
      >
        <ZoneForm onSubmit={handleCreateZone} />
      </Modal>

      {editZoneModal && (
        <Modal
          title={"Editar zona"}
          isOpen={Boolean(editZoneModal)}
          onClose={() => setEditZoneModal(null)}
          hiddenFooter
        >
          <ZoneForm
            onSubmit={handleEditZone}
            initValues={{
              ...editZoneModal,
              lubricants: editZoneModal.lubricants.map((lub) => lub.id),
            }}
          />
        </Modal>
      )}
    </>
  );
}
