import { LOGIN_MUTATION } from "../gql";
import type { LoginResponse } from "../types";
import { useMutation } from "@apollo/client";
import { useErrorLogger } from "utils/error/logError";

export const useLogin = () => {
  const [loginMutation] = useMutation<{ login: LoginResponse }>(LOGIN_MUTATION);
  const logError = useErrorLogger();
  const loginUser = async (email: string, password: string) => {
    try {
      const res = await loginMutation({
        variables: {
          loginUserInput: {
            email,
            password,
          },
        },
      });

      const token = res.data?.login.authToken;
      const user = res.data?.login.user;

      return { token, user };
    } catch (e) {
      logError(e);
      if (e instanceof Error && e.message === "Unauthorized") {
        return null;
      }
      throw e;
    }
  };

  return {
    loginUser,
  };
};
