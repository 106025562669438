import { useErrorLogger } from "../../utils/error/logError";
import * as API from "./gql";
import { useMutation } from "@apollo/client";
import { SessionStorage } from "@belray-ventec/platform-session";

export const useChangePassword = () => {
  const logError = useErrorLogger();
  const [changePasswordMutation] = useMutation(API.CHANGE_PASSWORD);

  const changePassword = async (data: {
    password: string;
    newPassword: string;
  }) => {
    try {
      const userId = SessionStorage.get()?.userId;
      const res = await changePasswordMutation({
        variables: {
          changePasswordInput: {
            id: userId,
            password: data.password,
            newPassword: data.newPassword,
          },
        },
      });
      return res.data?.user;
    } catch (e) {
      logError(e);
      throw e;
    }
  };

  return { changePassword };
};
