import {
  Drawer,
  DrawerItem,
  Icon,
  Container,
} from "@belray-ventec/geslub-platform-react-ui";
import { iconsType } from "@belray-ventec/geslub-platform-react-ui";
import {
  Avatar,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Heading,
  MenuGroup,
  Divider,
} from "@chakra-ui/react";
import { useAuth } from "core/auth/hooks";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routes";
import { USER_ROLES } from "utils/userRoles";

type INav = string[][];

export const PUBLIC_NAVS: INav = [["Plataformas", ROUTES.HOME, "Home"]];

export const ADMIN_NAVS: INav = [
  ["Usuarios", ROUTES.USERS, "User"],
  ["Zonas", ROUTES.ZONES, "Document"],
  ["Tipos de zonas", ROUTES.ZONE_TYPES, "Tag"],
];

export default function Layout() {
  const navigate = useNavigate();
  const auth = useAuth();
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("Plataformas");

  useEffect(() => {
    let title: string[] | undefined;
    const temporalRoutes = [...ADMIN_NAVS, ["Cuenta", ROUTES.ACCOUNT, "User"]];
    title = temporalRoutes.find((value) => value[1] === pathname);
    if (!title) {
      title = ["Plataformas"];
    }
    title && setTitle(title[0]);
  }, [pathname]);

  const onStateChange = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseSession = () => {
    auth.signout(() => navigate("/"));
  };

  const userRole = auth?.user?.homeConfig?.role?.id;

  return (
    <>
      <Drawer
        onLogoClick={() => navigate("/")}
        isOpen={isOpen}
        onStateChange={onStateChange}
        themeColor="#1A365D"
      >
        {PUBLIC_NAVS.map(([title, to, icon]) => (
          <Nav
            key={title}
            to={to}
            title={title}
            isOpen={isOpen}
            icon={icon}
            onClick={() => setIsOpen(false)}
            isActive={pathname === to}
          />
        ))}
        {userRole === USER_ROLES.ADMINISTRATOR &&
          ADMIN_NAVS.map(([title, to, icon]) => (
            <Nav
              key={title}
              to={to}
              title={title}
              isOpen={isOpen}
              icon={icon}
              onClick={() => setIsOpen(false)}
              isActive={pathname === to}
            />
          ))}
      </Drawer>

      <Box>
        <Container>
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            bgColor={"#1A365D"}
            borderRadius={"12px"}
            style={{
              marginBottom: "2.6rem",
              padding: "1.4em 2.4em",
            }}
          >
            <Heading size="lg" color="#fff">
              {title}
            </Heading>
            <MenuAvatar
              email={auth.user?.email}
              name={auth.user?.name}
              fullname={`${auth.user?.name} ${auth.user?.lastname}`}
              rol={auth.user?.homeConfig?.role?.label}
              avatar={auth.user?.avatar}
              handleCloseSession={handleCloseSession}
            />
          </Box>
          <Outlet />
        </Container>
      </Box>
    </>
  );
}

interface NavItemProps {
  to: string;
  isOpen: boolean;
  title: string;
  icon: string;
  onClick?: () => void;
  isActive: boolean;
}

const Nav = ({ to, isOpen, title, icon, onClick, isActive }: NavItemProps) => (
  <Link to={to} style={{ width: "100%" }}>
    <DrawerItem
      icon={<Icon color="#fff" size={25} icon={icon as iconsType} />}
      isOpen={isOpen}
      isActive={isActive}
      onClick={onClick}
    >
      {title}
    </DrawerItem>
  </Link>
);

interface IMenuAvatar {
  name?: string;
  avatar?: string;
  fullname?: string;
  rol?: string;
  handleCloseSession: () => void;
  email?: string;
}

const MenuAvatar = ({
  name,
  avatar,
  fullname,
  rol,
  email,
  handleCloseSession,
}: IMenuAvatar) => {
  return (
    <Menu>
      <MenuButton aria-label="User menu">
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar name={name} src={avatar} marginRight={"10px"} />

          <Icon icon="AngleDown" size={20} color={"white"} />
        </Box>
      </MenuButton>
      <MenuList zIndex={5}>
        <MenuGroup title={fullname} fontSize={"lg"}>
          <MenuGroup title={email} fontSize={"sm"}></MenuGroup>
          <MenuGroup title={rol} fontSize={"sm"}></MenuGroup>
          <Divider />
          <Link to={ROUTES.ACCOUNT}>
            <MenuItem fontSize="md" py={3}>
              Cambiar contraseña
            </MenuItem>
          </Link>
          <MenuItem onClick={handleCloseSession} fontSize="md" py={3}>
            Cerrar sesión
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
