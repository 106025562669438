import { Text, Box } from "@chakra-ui/react";

export default function notFound() {
  return (
    <Box>
      <Text fontSize="4xl" align="center">
        Página no encontrada.
      </Text>
    </Box>
  );
}
