import * as API from "./gql";
import { Lubricant } from "./types";
import { useQuery } from "@apollo/client";

export const useLubricantList = () => {
  const {
    loading,
    error,
    data: lubricantList = { findAllLubricants: [] },
  } = useQuery<{ findAllLubricants: Lubricant[] }>(API.FIND_All_LUBRICANTES);

  return {
    loading,
    error,
    lubricantList: lubricantList.findAllLubricants,
  };
};
