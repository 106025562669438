import * as API from "./gql";
import { ZoneTypeApi, CreateZoneType } from "./types";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

export const useZoneTypeList = () => {
  const {
    loading,
    error,
    data: zoneTypeList = { findAllZonesTypes: [] },
    refetch,
  } = useQuery<{ findAllZonesTypes: ZoneTypeApi[] }>(API.GET_ALL_ZONE_TYPE);

  const [createZoneType] = useMutation(API.CREATE_ZONE_TYPE, {
    onCompleted: () => refetch(),
  });
  const [updateZoneType] = useMutation(API.UPDATE_ZONE_TYPE, {
    onCompleted: () => refetch(),
  });
  const [disableZoneType] = useMutation(API.DISABLE_ZONE_TYPE, {
    onCompleted: () => refetch(),
  });
  const [removeZoneType] = useLazyQuery(API.REMOVE_ZONE_TYPE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => refetch(),
  });

  const onCreateZoneType = async (data: CreateZoneType): Promise<void> => {
    const newItem = { name: data.name, description: data.description };
    await createZoneType({
      variables: {
        createZoneType: newItem,
      },
    });
  };

  const onUpdateZoneType = async (
    id: ZoneTypeApi["id"],
    data: Partial<CreateZoneType>
  ): Promise<void> => {
    const newData: Partial<CreateZoneType> = {};
    if ("name" in data) newData.name = data.name;
    if ("description" in data) newData.description = data.description;
    if ("enabled" in data) newData.enabled = data.enabled;

    await updateZoneType({
      variables: {
        updateZoneTypeInput: {
          id,
          ...newData,
        },
      },
    });
  };

  const onDisableZoneType = async (id: ZoneTypeApi["id"]) => {
    await disableZoneType({
      variables: {
        updateZoneTypeInput: {
          id,
          enabled: false,
        },
      },
    });
  };

  const onRemoveZoneType = async (id: ZoneTypeApi["id"]): Promise<void> => {
    await removeZoneType({
      variables: {
        deleteZoneTypeId: id,
      },
    });
  };

  return {
    isLoadingZoneTypeList: loading,
    errorZoneTypeList: error,
    zoneTypeList: zoneTypeList.findAllZonesTypes,
    onCreateZoneType,
    onUpdateZoneType,
    onRemoveZoneType,
    onDisableZoneType,
  };
};
