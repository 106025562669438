import { Modal, Table } from "@belray-ventec/geslub-platform-react-ui";
import { Text, Box } from "@chakra-ui/react";
import { useState, ReactNode } from "react";

export interface AdminTableProps<T> {
  data: T[];
  columns: { label: string; getValue: (item: T) => ReactNode }[];
  onRemove?: (d: T, stopLoading: () => void) => void;
  onEdit?: (d: T) => void;
  onDisable?: (d: T, stopLoading: () => void) => void;
  onEnable?: (d: T) => void;
  getRowKey: (d: T) => string | number;
  caption?: string;
  getIfRowIsEnabled?: (d: T) => boolean;
  isLoading?: boolean;
}

export default function AdminTable<T extends Record<string, unknown>>({
  data,
  columns,
  onRemove,
  onEdit,
  onDisable,
  getRowKey,
  caption,
  onEnable,
  getIfRowIsEnabled = () => true,
  isLoading = false,
}: AdminTableProps<T>) {
  const [, setIsRemoving] = useState(false);
  const [toRemove, setToRemove] = useState<T | null>(null);
  const [disableItem, setDisableItem] = useState<T | null>(null);
  const [, setIsDisabling] = useState(false);

  const handleRemove = () => {
    setIsRemoving(true);
    if (toRemove && onRemove)
      onRemove(toRemove, () => {
        setIsRemoving(false);
        setToRemove(null);
      });
  };

  const handleDisable = () => {
    setIsDisabling(true);
    if (disableItem && onDisable)
      onDisable(disableItem, () => {
        setIsDisabling(false);
        setDisableItem(null);
      });
  };

  return (
    <Box>
      <Table
        data={data}
        columns={columns}
        getRowKey={getRowKey}
        showPages={true}
        getRowIsEnabled={getIfRowIsEnabled}
        showDisabled
        onDisabled={(item) => setDisableItem(item)}
        onEnabled={onEnable}
        onEdit={(item) => onEdit && onEdit(item)}
        onDeleteItem={(item) => setToRemove(item)}
        showAdminOptions
        caption={caption}
        isLoading={isLoading}
      />

      {isLoading && <Box>Cargando...</Box>}

      <Modal
        isOpen={Boolean(toRemove)}
        onClose={() => setToRemove(null)}
        actionText="Eliminar"
        onAction={() => handleRemove()}
        onCancel={() => setToRemove(null)}
        title="Eliminar registro"
      >
        <Text fontSize="2xl">
          ¿Estas seguro de querer eliminar el registro?
        </Text>
      </Modal>

      <Modal
        title="Deshabilitar registro"
        isOpen={Boolean(disableItem)}
        onClose={() => setDisableItem(null)}
        onCancel={() => setDisableItem(null)}
        cancelText="Cancelar"
        actionText="Deshabilitar"
        onAction={() => handleDisable()}
      >
        <Text fontSize="2xl">
          ¿Estas seguro de querer deshabilitar el registro?
        </Text>
      </Modal>
    </Box>
  );
}
