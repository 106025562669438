/**
 * Extrae el mensaje de error de cualquier objeto o valor lanzado como error.
 * Maneja instancias de Error, errores primitivos y objetos con estructuras no estándar.
 *
 * @param error - El error capturado, que puede ser de cualquier tipo.
 * @returns El mensaje de error como una cadena.
 */
export function getErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  }

  if (error == null) {
    return "Error desconocido sin más detalles.";
  }

  if (typeof error === "object" && "message" in error) {
    return (error as { message: unknown }).message as string;
  }

  return String(error);
}
