import { logEvent } from "firebase/analytics";
import { analytics } from "index";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function AnalyticPath({
  children,
}: {
  children: React.ReactNode;
}) {
  const { pathname } = useLocation();

  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_location: pathname,
      page_path: pathname,
      page_title: pathname,
    });
  }, [pathname]);

  return <>{children}</>;
}
