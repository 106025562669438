import * as API from "./gql";
import { ZoneApi, CreateZone } from "./types";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { API as apiConfig } from "configs";
import { fetchWithToken } from "utils/fetcher/fetchWithToken";

export const useZoneList = () => {
  const {
    loading,
    error,
    data: zoneList = { findAllZone: [] },
    refetch,
  } = useQuery<{ findAllZone: ZoneApi[] }>(API.GET_ALL_ZONES, {});

  const [createZone] = useMutation(API.CREATE_ZONE, {
    onCompleted: () => refetch(),
  });
  const [updateZone] = useMutation(API.UPDATE_ZONE, {
    onCompleted: () => refetch(),
  });
  const [removeZone] = useLazyQuery(API.REMOVE_ZONE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => refetch(),
  });

  const onCreateZone = async (data: CreateZone): Promise<void> => {
    const { type, ...rest } = data;

    await createZone({
      variables: {
        createZoneInput: { ...rest, typeId: type.id },
      },
    });
  };

  const onUpdateZone = async (
    id: string,
    data: Partial<CreateZone>
  ): Promise<void> => {
    const newData: Partial<{ typeId: string } & Omit<CreateZone, "type">> = {};
    if ("name" in data) newData.name = data.name;
    if ("description" in data) newData.description = data.description;
    if ("scheduledWeekendActivity" in data)
      newData.scheduledWeekendActivity = data.scheduledWeekendActivity;
    if ("scheduledActWaivableHolydays" in data)
      newData.scheduledActWaivableHolydays = data.scheduledActWaivableHolydays;
    if ("scheduledActInalienableHolydays" in data)
      newData.scheduledActInalienableHolydays =
        data.scheduledActInalienableHolydays;
    if ("validateSchedActByNfc" in data)
      newData.validateSchedActByNfc = data.validateSchedActByNfc;
    if (data.type?.id) newData.typeId = data.type.id;
    if ("sectorLevels" in data) newData.sectorLevels = data.sectorLevels;
    if ("equipmentInstanceLevels" in data)
      newData.equipmentInstanceLevels = data.equipmentInstanceLevels;
    if ("enabled" in data) newData.enabled = data.enabled;
    if ("lubricants" in data) newData.lubricants = data.lubricants;

    await updateZone({
      variables: {
        updateZoneInput: {
          id,
          ...newData,
        },
      },
    });
  };

  const onUpdateUserConfig = async (
    oldZone: ZoneApi,
    newZone: Partial<CreateZone>
  ) => {
    await fetchWithToken(
      apiConfig.GESLUB_REST + "/zonal-activity-config/remove-config-user",
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          newSectors: newZone.sectorLevels,
          newInstances: newZone.equipmentInstanceLevels,
        }),
      },
      {
        zoneId: oldZone.id,
      }
    );
  };

  const onRemoveZone = async (id: string): Promise<void> => {
    await removeZone({
      variables: {
        deleteZoneId: id,
      },
    });
  };

  return {
    isLoadingZoneList: loading,
    errorZoneList: error,
    zoneList: zoneList.findAllZone,
    onCreateZone,
    onUpdateZone,
    onRemoveZone,
    onUpdateUserConfig,
  };
};
