export function PlanningIcon({ size = 30 }: { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 100 100"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Trazado_240"
            data-name="Trazado 240"
            d="M520.68,902.66H509.71A43.6,43.6,0,0,0,479.4,914.9a44.472,44.472,0,0,0-6.99,8.64,43.525,43.525,0,0,0-6.59,23v10.97a43.436,43.436,0,0,0,6.59,23,44.094,44.094,0,0,0,30.52,20.34h-.2a61.418,61.418,0,0,0,12.32,1.37,57.209,57.209,0,0,0,12.32-1.37h.09a44.058,44.058,0,0,0,36.86-38.98V902.66Z"
            transform="translate(-465.83 -902.66)"
            fill="#163f65"
          />
        </clipPath>
      </defs>
      <g
        id="Grupo_883"
        data-name="Grupo 883"
        transform="translate(13929.253 17319.932)"
      >
        <g
          id="Grupo_699"
          data-name="Grupo 699"
          transform="translate(-14395.073 -18222.592)"
        >
          <g id="Grupo_495" data-name="Grupo 495">
            <path
              id="SVGID_9_"
              d="M520.68,902.66H509.71A43.6,43.6,0,0,0,479.4,914.9a44.472,44.472,0,0,0-6.99,8.64,43.525,43.525,0,0,0-6.59,23v10.97a43.436,43.436,0,0,0,6.59,23,44.094,44.094,0,0,0,30.52,20.34h-.2a61.418,61.418,0,0,0,12.32,1.37,57.209,57.209,0,0,0,12.32-1.37h.09a44.058,44.058,0,0,0,36.86-38.98V902.66Z"
              fill="#163f65"
            />
          </g>
          <g id="Grupo_497" data-name="Grupo 497">
            <g
              id="Grupo_496"
              data-name="Grupo 496"
              transform="translate(465.83 902.66)"
              clipPath="url(#clip-path)"
            >
              <rect
                id="Rectángulo_578"
                data-name="Rectángulo 578"
                width="98.49"
                height="99.55"
                fill="#163f65"
              />
            </g>
          </g>
        </g>
        <path
          id="Trazado_403"
          data-name="Trazado 403"
          d="M507.03,973.83l-17.77-17.77a6.032,6.032,0,0,1,8.53-8.53l9.23,9.24,28.33-28.33a6.032,6.032,0,0,1,8.53,8.53Z"
          transform="translate(-14395.75 -18222.445)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
