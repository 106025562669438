import { RequireAuth } from "core/auth/components";
import AuthProvider from "core/auth/context";
import Perfil from "pages/account";
import Home from "pages/home";
import Login from "pages/login";
import NoPlatformAuth from "pages/noPlatformAuth";
import NotFound from "pages/notFound";
import PasswordReset from "pages/passReset";
import Users from "pages/users";
import ZoneTypes from "pages/zoneTypes";
import Zones from "pages/zones";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Layout from "ui/layout";
import AnalyticPath from "utils/components/analyticsPath";
import { ROUTES } from "utils/routes";

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route
            path={ROUTES.LOGIN}
            element={
              <AnalyticPath>
                <Login />
              </AnalyticPath>
            }
          />
          <Route
            path={ROUTES.PASS_RESET}
            element={
              <AnalyticPath>
                <PasswordReset />
              </AnalyticPath>
            }
          />
          <Route
            path={ROUTES.NO_PLATFORM_AUTH}
            element={
              <AnalyticPath>
                <NoPlatformAuth />
              </AnalyticPath>
            }
          />
          <Route
            path="/"
            element={
              <RequireAuth>
                <AnalyticPath>
                  <Layout />
                </AnalyticPath>
              </RequireAuth>
            }
          >
            <Route
              index
              element={
                <AnalyticPath>
                  <Home />
                </AnalyticPath>
              }
            />
            <Route
              path={ROUTES.USERS}
              element={
                <AnalyticPath>
                  <Users />
                </AnalyticPath>
              }
            />
            <Route
              path={ROUTES.ACCOUNT}
              element={
                <AnalyticPath>
                  <Perfil />
                </AnalyticPath>
              }
            />
            <Route
              path={ROUTES.ZONES}
              element={
                <AnalyticPath>
                  <Zones />
                </AnalyticPath>
              }
            />
            <Route
              path={ROUTES.ZONE_TYPES}
              element={
                <AnalyticPath>
                  <ZoneTypes />
                </AnalyticPath>
              }
            />
            <Route
              path="*"
              element={
                <AnalyticPath>
                  <NotFound />
                </AnalyticPath>
              }
            />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
