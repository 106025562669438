import { useToast, AlertStatus } from "@chakra-ui/react";

export function useShowToast() {
  const toast = useToast();

  const showToast = (
    title: string,
    description: string,
    status: AlertStatus
  ) => {
    toast({
      title: title,
      description: description,
      status: status,
      isClosable: true,
    });
  };

  return { showToast };
}
