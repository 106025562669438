import { CreateZoneType } from "../types";
import { Button, FormControl, FormLabel, Input, Box } from "@chakra-ui/react";
import { useState } from "react";

export interface ZoneTypeFormProps {
  onSubmit: (d: CreateZoneType, stopLoading: () => void) => void;
  initValues?: Partial<CreateZoneType> | undefined;
}

export default function ZoneTypeForm({
  onSubmit,
  initValues,
}: ZoneTypeFormProps) {
  const [formData, setFormData] = useState<CreateZoneType>({
    name: initValues?.name ?? "",
    description: initValues?.description ?? "",
  });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={(e) => {
        setIsLoading(true);
        e.preventDefault();
        onSubmit(formData, () => setIsLoading(false));
      }}
    >
      <Box display="flex" flexDirection="column" gap="1rem">
        <FormControl isRequired>
          <FormLabel htmlFor="name">Nombre</FormLabel>
          <Input
            id="name"
            autoComplete="off"
            onChange={(e) =>
              setFormData({ ...formData, name: e.currentTarget.value })
            }
            value={formData.name}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="description">Descripción</FormLabel>
          <Input
            id="description"
            autoComplete="off"
            onChange={(e) =>
              setFormData({ ...formData, description: e.currentTarget.value })
            }
            value={formData.description}
          />
        </FormControl>

        <Button variant="primary" type="submit" isLoading={isLoading}>
          Aceptar
        </Button>
      </Box>
    </form>
  );
}
