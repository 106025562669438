import { extendTheme } from "@chakra-ui/react";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Button: ComponentStyleConfig = {
  variants: {
    primary: {
      bg: "#173C5E",
      color: "onPrimary",
      _hover: {
        background: "#225b8f !important",
      },
      _disabled: {
        _hover: {
          background: "#173C5E !important",
        },
      },
    },
    secondary: {
      bg: "secondary",
      color: "onSecondary",
    },
    danger: {
      bg: "danger",
      color: "onDanger",
    },
  },
};

export const theme = extendTheme({
  styles: {
    global: {
      ":not(.chakra-dont-set-collapse) > .chakra-collapse": {
        overflow: "initial !important",
      },
      body: {
        fontSize: "16px",
      },
    },
  },
  colors: {
    primary: "#173C5E",
    onPrimary: "#FFFFFF",
    secondary: "#D82F11",
    onSecondary: "#FFFFFF",
    background: "#f8f8f8",
    danger: "#E53E3E",
    onDanger: "#FFFFFF",
    layout: "#FFFFFF",
  },
  components: {
    Button,
  },
});
