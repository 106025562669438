import { ApolloError } from "@apollo/client";
import { GraphQLErrorExtensions, GraphQLError } from "graphql";

enum GraphQlErrors {
  BAD_USER_INPUT = "BAD_USER_INPUT",
}

export const getErrors = (error: unknown) => {
  const listErrors: string[] = [];
  if (error instanceof ApolloError) {
    error.graphQLErrors.map((error: GraphQLError) => {
      const extension = error.extensions;
      const graphqlErrors = extension.response as GraphQLErrorExtensions;
      switch (extension.code) {
        case GraphQlErrors.BAD_USER_INPUT:
          (graphqlErrors.message as string[]).map((error) =>
            listErrors.push(error)
          );
          break;
      }
    });
  }
  return listErrors;
};
