import { Box } from "@chakra-ui/react";
import ChangePasswordForm, {
  ChangePasswordFormProps,
} from "core/account/forms/changePasswordForm";
import { useChangePassword } from "core/account/hooks";
import { useErrorLogger } from "utils/error/logError";
import { useShowToast } from "utils/hooks/useShowToast";

export default function Account() {
  const { showToast } = useShowToast();
  const logError = useErrorLogger();
  const { changePassword } = useChangePassword();

  const handleChangePassword: ChangePasswordFormProps["onSubmit"] = async (
    form,
    stopLoading
  ) => {
    try {
      const { newPassword, newPassword2 } = form;

      if (newPassword !== newPassword2) {
        return showToast("Error", "Nuevas contraseñas no coinciden", "error");
      }

      await changePassword(form);
      showToast(
        "Contraseña actualizada",
        "La contraseña ha sido actualizada",
        "success"
      );
    } catch (e) {
      logError(e);
      showToast("Error", "Contraseña actual no coincide", "error");
    } finally {
      stopLoading();
    }
  };

  return (
    <Box
      shadow="md"
      px={30}
      py={30}
      w={["100%", "100%", "600px", "600px"]}
      mx="auto"
    >
      <ChangePasswordForm onSubmit={handleChangePassword} />
    </Box>
  );
}
