import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
  query FindAllUser {
    findAllUser {
      id
      name
      lastname
      rut
      job
      email
      phone
      avatar
      enabled
      zones {
        id
        name
        description
      }
      libraryConfig {
        id
        authorization
        role {
          id
          label
        }
      }
      planningConfig {
        id
        authorization
        role {
          id
          label
        }
      }
      homeConfig {
        id
        authorization
        role {
          id
          label
        }
      }
      dashboardConfig {
        id
        authorization
        role {
          id
          label
        }
      }
      datareportConfig {
        id
        authorization
        role {
          id
          label
        }
      }
      stocksConfig {
        id
        authorization
        role {
          id
          label
        }
      }
      tribolabConfig {
        id
        authorization
        role {
          id
          label
        }
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
    }
  }
`;

export const REMOVE_USER = gql`
  query DeleteUser($deleteUserId: String!) {
    deleteUser(id: $deleteUserId) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      id
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query FindUserById($findUserByIdId: String!) {
    findUserById(id: $findUserByIdId) {
      id
      name
      lastname
      rut
      job
      email
      phone
      enabled
      homeConfig {
        id
        authorization
        role {
          id
          label
        }
      }
      planningConfig {
        id
        authorization
        role {
          id
          label
        }
      }
      libraryConfig {
        id
        authorization
        role {
          id
          label
        }
      }
      stocksConfig {
        id
        authorization
        role {
          id
          label
        }
      }
      tribolabConfig {
        id
        authorization
        role {
          id
          label
        }
      }
      zones {
        id
        name
        description
        type {
          id
          name
        }
      }
    }
  }
`;
