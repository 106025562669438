import { gql } from "@apollo/client";

export const GET_ALL_ZONES = gql`
  query FindAllZone {
    findAllZone {
      id
      name
      description
      type {
        id
        name
        description
      }
      sectorLevels
      equipmentInstanceLevels
      scheduledWeekendActivity
      scheduledActWaivableHolydays
      scheduledActInalienableHolydays
      validateSchedActByNfc
      enabled
      lubricants {
        id: libraryCode
        name
      }
    }
  }
`;
export const CREATE_ZONE = gql`
  mutation CreateZone($createZoneInput: ZoneInput!) {
    createZone(createZoneInput: $createZoneInput) {
      id
    }
  }
`;

export const REMOVE_ZONE = gql`
  query DeleteZone($deleteZoneId: String!) {
    deleteZone(id: $deleteZoneId) {
      id
    }
  }
`;

export const UPDATE_ZONE = gql`
  mutation UpdateZone($updateZoneInput: UpdateZoneInput!) {
    updateZone(updateZoneInput: $updateZoneInput) {
      id
    }
  }
`;
