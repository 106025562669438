import { useAuth } from "../hooks";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "utils/routes";

export function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth?.user) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
  }

  if (!auth.user.homeConfig?.authorization) {
    return <Navigate to={ROUTES.NO_PLATFORM_AUTH} replace />;
  }

  return children;
}
