import { LoginInput } from "../types";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Box,
  Icon,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

export interface LoginFormProps {
  onSubmit: (v: LoginInput, f: (b: boolean) => void) => void;
  initValues?: Partial<LoginInput> | undefined;
}

export default function LoginForm({ onSubmit, initValues }: LoginFormProps) {
  const [formData, setFormData] = useState<LoginInput>({
    email: initValues?.email ?? "",
    password: initValues?.password ?? "",
  });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setIsLoading(true);
        onSubmit(formData, () => setIsLoading(false));
        setFormData({ ...formData, password: "" });
      }}
    >
      <Box display="flex" flexDirection="column" gap="1.6rem">
        <FormControl isRequired>
          <FormLabel htmlFor="email">Correo</FormLabel>
          <Input
            id="email"
            autoComplete="off"
            value={formData.email}
            type="email"
            placeholder="correo@gmail.com"
            onChange={(e) =>
              setFormData({ ...formData, email: e.currentTarget.value })
            }
            minLength={6}
          />
        </FormControl>

        <PasswordInput
          id="password"
          value={formData.password}
          onChange={(e) =>
            setFormData({ ...formData, password: e.currentTarget.value })
          }
        />

        <Button variant="primary" type="submit" isLoading={isLoading}>
          Ingresar
        </Button>
      </Box>
    </form>
  );
}

interface PasswordInputProps {
  id: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  label?: string;
}

export function PasswordInput({
  id,
  value,
  onChange,
  label = "Contraseña",
}: PasswordInputProps) {
  const [showPass, setShowPass] = useState(false);

  const handleTogglePass = () => setShowPass(!showPass);

  return (
    <>
      <FormControl isRequired>
        <FormLabel htmlFor={id}>{label}</FormLabel>
        <InputGroup>
          <InputRightElement>
            <Button
              variant={"ghost"}
              aria-label="Mostrar contraseña"
              onClick={handleTogglePass}
            >
              {showPass ? (
                <Icon as={MdVisibilityOff} w={4} h={4} />
              ) : (
                <Icon as={MdVisibility} w={4} h={4} />
              )}
            </Button>
          </InputRightElement>

          <Input
            type={showPass ? "text" : "password"}
            id={id}
            autoComplete="off"
            placeholder="*******"
            value={value}
            onChange={onChange}
          />
        </InputGroup>
      </FormControl>
    </>
  );
}
