import { Paragraph } from "@belray-ventec/geslub-platform-react-ui";
import { Tag, TagLabel, TagLeftIcon, Box } from "@chakra-ui/react";
import React from "react";
import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

export interface PlatformCardProps {
  image: string;
  title: string;
  icon: React.ReactNode;
  link: string;
  isAuthorized?: boolean;
}

export function PlatformCard({
  image,
  title,
  icon,
  link,
  isAuthorized,
}: PlatformCardProps) {
  return (
    <a
      tabIndex={0}
      role="link"
      style={{ textDecoration: "none", color: "var(--colors-primary)" }}
      href={link}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="300px"
        border="1px solid #ccc"
        borderRadius="5px"
      >
        <Box position="relative" width="100%" height="200px">
          {isAuthorized ? (
            <Tag
              position="absolute"
              top="10px"
              right="10px"
              zIndex={1}
              size={"md"}
              variant="subtle"
              colorScheme="green"
            >
              <TagLeftIcon boxSize="12px" as={FaCheck} />
              <TagLabel>Tienes acceso</TagLabel>
            </Tag>
          ) : (
            <Tag
              position="absolute"
              top="10px"
              right="10px"
              zIndex={1}
              size={"md"}
              variant="subtle"
              colorScheme="red"
            >
              <TagLeftIcon boxSize="12px" as={IoClose} />
              <TagLabel>Sin acceso</TagLabel>
            </Tag>
          )}

          <img
            style={{
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              position: "absolute",
              height: "200px",
            }}
            src={image}
          />
          <span
            style={{
              position: "absolute",
              bottom: "10px",
              left: "10px",
              fontSize: "2rem",
              color: "#fff",
              fontWeight: "300",
            }}
          >
            {title}
          </span>
        </Box>
        <Box padding="0.5rem" display="flex" justifyContent="space-between">
          <Box>
            <Paragraph size="md">Geslub</Paragraph>
            <Paragraph size="md">Platform</Paragraph>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            {icon}
          </Box>
        </Box>
      </Box>
    </a>
  );
}
