import { useErrorLogger } from "../../utils/error/logError";
import { Icon, Modal } from "@belray-ventec/geslub-platform-react-ui";
import { Button, Box } from "@chakra-ui/react";
import ZoneTypeForm, {
  ZoneTypeFormProps,
} from "core/zoneTypes/forms/ZoneTypeForm";
import { useZoneTypeList } from "core/zoneTypes/hooks";
import { ZoneTypeApi } from "core/zoneTypes/types";
import { useEffect, useState } from "react";
import AdminTable, { AdminTableProps } from "ui/tables";
import { getErrors } from "utils/apollo/handlerErrors";
import { useShowToast } from "utils/hooks/useShowToast";

// TODO re dirigir al usuario si no es adminsitrador

export default function ZoneTypes() {
  const logError = useErrorLogger();
  const [createCompanyModal, setCreateZoneTypeModal] = useState(false);
  const [editZoneTypeModal, setEditZoneTypeModal] =
    useState<ZoneTypeApi | null>(null);

  const { showToast } = useShowToast();
  const ZoneTypesList = useZoneTypeList();

  useEffect(() => {
    if (ZoneTypesList.errorZoneTypeList) {
      showToast("Error", "No se han podido cargar los tipos de zonas", "error");
    }
  }, [ZoneTypesList.errorZoneTypeList]);

  const handleCreateZoneType: ZoneTypeFormProps["onSubmit"] = async (
    data,
    stopLoading
  ) => {
    try {
      await ZoneTypesList.onCreateZoneType(data);
      stopLoading();
      setCreateZoneTypeModal(false);
    } catch (e) {
      logError(e);
      const errors = getErrors(e);
      showToast(
        "Error al crear tipo de zona",
        `${errors.map((error) => <p key={error}> {error} </p>)}`,
        "error"
      );
    }
  };

  const handleEditZoneType: ZoneTypeFormProps["onSubmit"] = async (
    data,
    stopLoading
  ) => {
    try {
      if (editZoneTypeModal)
        await ZoneTypesList.onUpdateZoneType(editZoneTypeModal.id, data);
      stopLoading();
      setEditZoneTypeModal(null);
    } catch (e) {
      logError(e);
      const errors = getErrors(e);
      showToast(
        "Error",
        `${errors.map((error) => <p key={error}> {error} </p>)}`,
        "error"
      );
      stopLoading();
    }
  };

  const handleDisableZoneType: AdminTableProps<ZoneTypeApi>["onDisable"] =
    async (data, stopLoading) => {
      try {
        await ZoneTypesList.onDisableZoneType(data.id);
      } catch (e) {
        logError(e);
        showToast(
          "Error",
          "No se ha podido deshabilitar el tipo de zona",
          "error"
        );
      } finally {
        stopLoading();
      }
    };

  const handleEnableZoneType: AdminTableProps<ZoneTypeApi>["onEnable"] = async (
    data
  ) => {
    try {
      await ZoneTypesList.onUpdateZoneType(data.id, { enabled: true });
    } catch (e) {
      logError(e);
      showToast("Error", "No se ha podido habilitar el tipo de zona", "error");
    }
  };

  return (
    <>
      <Box>
        <Button
          leftIcon={<Icon color="#fff" size={15} icon="Add" />}
          variant="primary"
          onClick={() => setCreateZoneTypeModal(true)}
        >
          Crear tipo de zona
        </Button>

        <AdminTable
          data={ZoneTypesList.zoneTypeList}
          columns={[
            { label: "Nombre", getValue: (zoneType) => zoneType.name },
            {
              label: "Descripción",
              getValue: (zoneType) => zoneType.description,
            },
          ]}
          getRowKey={(item) => item.id}
          onEdit={(item) => setEditZoneTypeModal(item)}
          onDisable={handleDisableZoneType}
          caption="Lista de tipos de zonas"
          getIfRowIsEnabled={(item) => item.enabled}
          onEnable={handleEnableZoneType}
        />
      </Box>

      <Modal
        title={"Crear tipo de zona"}
        isOpen={createCompanyModal}
        onClose={() => setCreateZoneTypeModal(false)}
        hiddenFooter
      >
        <ZoneTypeForm onSubmit={handleCreateZoneType} />
      </Modal>

      {editZoneTypeModal && (
        <Modal
          title={"Modificar tipo de zona"}
          isOpen={Boolean(editZoneTypeModal)}
          onClose={() => setEditZoneTypeModal(null)}
          hiddenFooter
        >
          <ZoneTypeForm
            onSubmit={handleEditZoneType}
            initValues={editZoneTypeModal}
          />
        </Modal>
      )}
    </>
  );
}
