export const formatRut = (rut: string): string => {
  const cleanRut = rut.replace(/[^0-9kK]/g, "").toUpperCase();

  const body = cleanRut.slice(0, -1);
  const dv = cleanRut.slice(-1);

  if (body) {
    return `${body}-${dv}`;
  }

  return cleanRut;
};
