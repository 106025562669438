export function TribolabIcon({ size = 30 }: { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 100 100"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Trazado_219"
            data-name="Trazado 219"
            d="M527.44,1108.48H516.47a43.6,43.6,0,0,0-30.31,12.24,44.47,44.47,0,0,0-6.99,8.64,43.525,43.525,0,0,0-6.59,23v10.97a43.436,43.436,0,0,0,6.59,23,44.094,44.094,0,0,0,30.52,20.34h-.2a61.413,61.413,0,0,0,12.32,1.37,57.209,57.209,0,0,0,12.32-1.37h.09a44.059,44.059,0,0,0,36.86-38.98v-59.21Z"
            transform="translate(-472.59 -1108.47)"
          />
        </clipPath>
      </defs>
      <g
        id="Grupo_886"
        data-name="Grupo 886"
        transform="translate(14063 17319.932)"
      >
        <g
          id="Grupo_694"
          data-name="Grupo 694"
          transform="translate(-14535.58 -18428.412)"
        >
          <g id="Grupo_487" data-name="Grupo 487">
            <path
              id="SVGID_7_"
              d="M527.44,1108.48H516.47a43.6,43.6,0,0,0-30.31,12.24,44.47,44.47,0,0,0-6.99,8.64,43.525,43.525,0,0,0-6.59,23v10.97a43.436,43.436,0,0,0,6.59,23,44.094,44.094,0,0,0,30.52,20.34h-.2a61.413,61.413,0,0,0,12.32,1.37,57.209,57.209,0,0,0,12.32-1.37h.09a44.059,44.059,0,0,0,36.86-38.98v-59.21Z"
              fill="#163f65"
            />
          </g>
          <g id="Grupo_489" data-name="Grupo 489">
            <g
              id="Grupo_488"
              data-name="Grupo 488"
              transform="translate(472.59 1108.47)"
              clipPath="url(#clip-path)"
            >
              <rect
                id="Rectángulo_577"
                data-name="Rectángulo 577"
                width="98.49"
                height="99.55"
                fill="#163f65"
              />
            </g>
          </g>
        </g>
        <g
          id="Grupo_695"
          data-name="Grupo 695"
          transform="translate(-14536.18 -18426.293)"
        >
          <path
            id="Trazado_228"
            data-name="Trazado 228"
            d="M549.44,1175.94l-13.27-13.27a25.619,25.619,0,0,1-7.67,7.67l13.27,13.27a5.424,5.424,0,0,0,7.67-7.67Z"
            fill="#fff"
          />
          <path
            id="Trazado_229"
            data-name="Trazado 229"
            d="M536.56,1149.05a21.69,21.69,0,1,0-21.69,21.69A21.689,21.689,0,0,0,536.56,1149.05Zm-21.69,16.27a16.27,16.27,0,1,1,16.27-16.27A16.29,16.29,0,0,1,514.87,1165.32Z"
            fill="#fff"
          />
          <path
            id="Trazado_230"
            data-name="Trazado 230"
            d="M502.21,1149.05h3.62a9.056,9.056,0,0,1,9.04-9.04v-3.61A12.66,12.66,0,0,0,502.21,1149.05Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
