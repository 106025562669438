import { ChangePassword } from "../types";
import { Button, FormControl, Box, AlertIcon, Alert } from "@chakra-ui/react";
import { PasswordInput } from "core/session/forms/LoginForm";
import { useState } from "react";

const INIT_FORM_STATE: ChangePassword = {
  password: "",
  newPassword: "",
  newPassword2: "",
};

export interface ChangePasswordFormProps {
  onSubmit: (d: ChangePassword, stopLoading: () => void) => void;
}

export default function ChangePasswordForm({
  onSubmit,
}: ChangePasswordFormProps) {
  const [formData, setFormData] = useState<ChangePassword>(INIT_FORM_STATE);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    onSubmit(formData, () => setIsLoading(false));
    setFormData(INIT_FORM_STATE);
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gap="0.5rem">
        <FormControl mb={5} isRequired>
          <PasswordInput
            label="Contraseña actual"
            id="password"
            onChange={(e) =>
              setFormData({ ...formData, password: e.currentTarget.value })
            }
            value={formData.password}
          />
        </FormControl>

        <FormControl mb={5} isRequired>
          <PasswordInput
            label="Nueva contraseña"
            id="newPassword"
            onChange={(e) =>
              setFormData({ ...formData, newPassword: e.currentTarget.value })
            }
            value={formData.newPassword}
          />
        </FormControl>

        <FormControl mb={5} isRequired>
          <PasswordInput
            label="Repetir nueva contraseña"
            id="newPassword2"
            onChange={(e) =>
              setFormData({ ...formData, newPassword2: e.currentTarget.value })
            }
            value={formData.newPassword2}
          />
        </FormControl>
        <Alert
          fontSize={"sm"}
          my={2}
          py={3}
          px={3}
          bgColor={"gray.100"}
          status="info"
        >
          <AlertIcon />
          Nueva contraseña debe tener al menos 6 caracteres.
        </Alert>

        <Button
          variant="primary"
          isLoading={isLoading}
          loadingText="Cargando"
          type="submit"
        >
          Guardar
        </Button>
      </Box>
    </form>
  );
}
