import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from "@chakra-ui/react";
import LIBRARY_IMAGE from "assets/images/platforms/library.jpg";
import PLANNING_IMAGE from "assets/images/platforms/planning.jpg";
import STOCKS_IMAGE from "assets/images/platforms/stocks.jpg";
import TRIBOLAB_IMAGE from "assets/images/platforms/tribolab.jpg";
import { GESLUB_PLATFORM_URLS } from "configs";
import { useAuth } from "core/auth/hooks";
import { PlatformCard } from "ui/cards/PlatformCard";
import { PlanningIcon, LibraryIcon, TribolabIcon, StocksIcon } from "ui/icons";

const ICON_SIZE = 35;
const LINK_FORM =
  "https://docs.google.com/forms/d/e/1FAIpQLSctbSlOuuk-DPQBSUUSA5GCAXixGgnO9FD8it8Cz-hwfDIN0Q/viewform";
interface getPlatformProps {
  homeAuth?: boolean;
  planningAuth?: boolean;
  libraryAuth?: boolean;
  tribolabAuth?: boolean;
  stocksAuth?: boolean;
}

const getUserPlatforms = ({
  planningAuth,
  libraryAuth,
  tribolabAuth,
  stocksAuth,
}: getPlatformProps) => [
  {
    title: "Planning",
    link: GESLUB_PLATFORM_URLS.PLANNING,
    image: PLANNING_IMAGE,
    icon: <PlanningIcon key="planning" size={ICON_SIZE} />,
    isAuthorized: planningAuth,
  },
  {
    title: "Library",
    link: GESLUB_PLATFORM_URLS.LIBRARY,
    image: LIBRARY_IMAGE,
    icon: <LibraryIcon key="library" size={ICON_SIZE} />,
    isAuthorized: libraryAuth,
  },
  {
    title: "Tribolab",
    link: GESLUB_PLATFORM_URLS.TRIBOLAB,
    image: TRIBOLAB_IMAGE,
    icon: <TribolabIcon key="tribolab" size={ICON_SIZE} />,
    isAuthorized: tribolabAuth,
  },
  {
    title: "Vales",
    link: GESLUB_PLATFORM_URLS.STOCKS,
    image: STOCKS_IMAGE,
    icon: <StocksIcon key="stocks" size={ICON_SIZE} />,
    isAuthorized: stocksAuth,
  },
];

export default function Home() {
  const { user } = useAuth();

  const platforms = getUserPlatforms({
    planningAuth: user?.planningConfig.authorization,
    libraryAuth: user?.libraryConfig.authorization,
    tribolabAuth: user?.tribolabConfig.authorization,
    stocksAuth: user?.stocksConfig.authorization,
  });

  return (
    <Box
      display="grid"
      placeContent="center"
      gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
      gridGap={10}
    >
      <Alert
        status="info"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="300px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          ¿Necesitas acceso?
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          Si necesitas acceso a otra plataforma, completa el formulario en este{" "}
          <a href={LINK_FORM} target="_blank" rel="noopener noreferrer">
            <b>enlace</b>
          </a>
          .
        </AlertDescription>
      </Alert>
      {platforms.map(({ title, link, image, icon, isAuthorized }) => (
        <PlatformCard
          key={link}
          link={link}
          image={image}
          icon={icon}
          title={title}
          isAuthorized={isAuthorized}
        />
      ))}
    </Box>
  );
}
