import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation Login($loginUserInput: LoginUserInput!) {
    login(loginUserInput: $loginUserInput) {
      authToken
      user {
        id
        name
        job
        rut
        lastname
        email
        phone
        avatar
        enabled
        homeConfig {
          id
          authorization
          role {
            id
            label
          }
        }
        planningConfig {
          id
          authorization
          role {
            id
            label
          }
        }
        libraryConfig {
          id
          authorization
          role {
            id
            label
          }
        }
        stocksConfig {
          id
          authorization
          role {
            id
            label
          }
        }
        tribolabConfig {
          id
          authorization
          role {
            id
            label
          }
        }
      }
    }
  }
`;
