import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "./utils/normalize.css";
import { ApolloProvider } from "@apollo/client";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "App";
import { SENTRY } from "configs";
import { getAnalytics } from "firebase/analytics";
import ReactDOM from "react-dom/client";
import { client } from "utils/apollo";
import { initFirebaseApp } from "utils/hosting/index";
import { theme } from "utils/theme";

Sentry.init({
  dsn: SENTRY.DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV ?? "development",
});

const app = initFirebaseApp();
export const analytics = getAnalytics(app);
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ApolloProvider client={client}>
    <ColorModeScript />
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </ApolloProvider>
);

serviceWorker.unregister();

reportWebVitals();
