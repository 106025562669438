import { EmailReset } from "../types";
import { Button, FormControl, FormLabel, Input, Box } from "@chakra-ui/react";
import { useState } from "react";
import format from "utils/function/rutFormat";

export interface ResetEmailFormProps {
  onSubmit: (d: EmailReset, stopLoading: () => void) => void;
  initValues?: Partial<EmailReset> | undefined;
}

export default function ResetForm({
  onSubmit,
  initValues,
}: ResetEmailFormProps) {
  const [formData, setFormData] = useState<EmailReset>({
    rut: initValues?.rut ?? "",
    email: initValues?.email ?? "",
  });

  const [isLoading, setIsLoading] = useState(false);

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={(e) => {
        e.preventDefault();
        setIsLoading(true);
        onSubmit(formData, () => setIsLoading(false));
      }}
    >
      <Box display="flex" flexDirection="column" gap="1.6rem">
        <FormControl isRequired>
          <FormLabel htmlFor="rut">RUT</FormLabel>
          <Input
            id="rut"
            type="text"
            onChange={(e) =>
              setFormData({
                ...formData,
                rut: format(e.target.value),
              })
            }
            value={formData.rut}
            placeholder="12999999-7"
            autoComplete="off"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel htmlFor="email">Correo</FormLabel>
          <Input
            id="email"
            type="email"
            onChange={(e) =>
              setFormData({
                ...formData,
                email: e.target.value,
              })
            }
            value={formData.email}
            placeholder="correo@gmail.com"
            autoComplete="off"
          />
        </FormControl>

        <Button variant="primary" type="submit" isLoading={isLoading}>
          Enviar
        </Button>
      </Box>
    </form>
  );
}
