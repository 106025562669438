import { SessionStorage } from "@belray-ventec/platform-session";

export const fetchWithToken = async (
  url: string,
  options: RequestInit,
  args: {
    zoneId?: string;
    lastSync?: string;
  }
) => {
  const session = SessionStorage.get();
  const { zoneId = "", lastSync = new Date(0).toISOString() } = args;
  const response = await fetch(
    url +
      "?" +
      new URLSearchParams({
        zoneId,
        lastSync: lastSync,
      }),
    {
      ...options,
      headers: {
        ...options.headers,
        Authorization: "Bearer " + session?.authToken,
      },
    }
  );
  return response;
};
