import gql from "graphql-tag";

export const FIND_All_LUBRICANTES = gql`
  query FindAllLubricants {
    findAllLubricants {
      id: libraryCode
      name
    }
  }
`;
