import type { UserRole } from "../../roles/types";
import type { PlataformaConfig, UserForm as IUserForm } from "../types";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
  AccordionIcon,
  Checkbox,
  FormErrorMessage,
} from "@chakra-ui/react";
import { DEFAULT_USER_CONFIGS } from "core/roles/consts";
import { useUserRoles } from "core/roles/hooks";
import { useZoneList } from "core/zones/hooks";
import { useState } from "react";
import Select from "react-select";
import { formatRut } from "utils/formatRut/formatRut";

export interface UserFormProps {
  onSubmit: (d: Omit<IUserForm, "id">, stopLoading: () => void) => void;
  initValues?: Partial<IUserForm> | undefined;
  errorMessage?: string | null;
}

export default function UserForm({
  onSubmit,
  initValues,
  errorMessage,
}: UserFormProps) {
  const { userRolesList, isLoadingPlatform } = useUserRoles();
  const { zoneList } = useZoneList();

  const [formData, setFormData] = useState<Omit<IUserForm, "id">>({
    job: initValues?.job ?? "",
    name: initValues?.name ?? "",
    lastname: initValues?.lastname ?? "",
    rut: initValues?.rut ?? "",
    email: initValues?.email ?? "",
    phone: initValues?.phone ?? "",
    zones: initValues?.zones ?? [],
    adminConfig: {
      id: initValues?.adminConfig?.id ?? "",
      authorization:
        initValues?.adminConfig?.authorization ??
        DEFAULT_USER_CONFIGS.admin.authorization,
      role: {
        id:
          initValues?.adminConfig?.role?.id ??
          DEFAULT_USER_CONFIGS.admin.id_role,
        label:
          initValues?.adminConfig?.role?.label ??
          DEFAULT_USER_CONFIGS.admin.label,
      },
    },
    planningConfig: {
      id: initValues?.planningConfig?.id ?? "",
      authorization:
        initValues?.planningConfig?.authorization ??
        DEFAULT_USER_CONFIGS.planning.authorization,
      role: {
        id:
          initValues?.planningConfig?.role?.id ??
          DEFAULT_USER_CONFIGS.planning.id_role,
        label:
          initValues?.planningConfig?.role?.label ??
          DEFAULT_USER_CONFIGS.planning.label,
      },
    },
    homeConfig: {
      id: initValues?.homeConfig?.id ?? "",
      authorization:
        initValues?.homeConfig?.authorization ??
        DEFAULT_USER_CONFIGS.platform.authorization,
      role: {
        id:
          initValues?.homeConfig?.role?.id ??
          DEFAULT_USER_CONFIGS.platform.id_role,
        label:
          initValues?.homeConfig?.role?.label ??
          DEFAULT_USER_CONFIGS.platform.label,
      },
    },
    datareportConfig: {
      id: initValues?.datareportConfig?.id ?? "",
      authorization:
        initValues?.datareportConfig?.authorization ??
        DEFAULT_USER_CONFIGS.datareport.authorization,
      role: {
        id:
          initValues?.datareportConfig?.role?.id ??
          DEFAULT_USER_CONFIGS.datareport.id_role,
        label:
          initValues?.datareportConfig?.role?.label ??
          DEFAULT_USER_CONFIGS.datareport.label,
      },
    },
    libraryConfig: {
      id: initValues?.libraryConfig?.id ?? "",
      authorization:
        initValues?.libraryConfig?.authorization ??
        DEFAULT_USER_CONFIGS.library.authorization,
      role: {
        id:
          initValues?.libraryConfig?.role?.id ??
          DEFAULT_USER_CONFIGS.library.id_role,
        label:
          initValues?.libraryConfig?.role?.label ??
          DEFAULT_USER_CONFIGS.library.label,
      },
    },
    stocksConfig: {
      id: initValues?.stocksConfig?.id ?? "",
      authorization:
        initValues?.stocksConfig?.authorization ??
        DEFAULT_USER_CONFIGS.stocks.authorization,
      role: {
        id:
          initValues?.stocksConfig?.role?.id ??
          DEFAULT_USER_CONFIGS.stocks.id_role,
        label:
          initValues?.stocksConfig?.role.label ??
          DEFAULT_USER_CONFIGS.stocks.label,
      },
    },
    dashboardConfig: {
      id: initValues?.dashboardConfig?.id ?? "",
      authorization:
        initValues?.dashboardConfig?.authorization ??
        DEFAULT_USER_CONFIGS.dashboard.authorization,
      role: {
        id:
          initValues?.dashboardConfig?.role?.id ??
          DEFAULT_USER_CONFIGS.dashboard.id_role,
        label:
          initValues?.dashboardConfig?.role?.label ??
          DEFAULT_USER_CONFIGS.dashboard.label,
      },
    },
    tribolabConfig: {
      id: initValues?.tribolabConfig?.id ?? "",
      authorization:
        initValues?.tribolabConfig?.authorization ??
        DEFAULT_USER_CONFIGS.tribolab.authorization,
      role: {
        id:
          initValues?.tribolabConfig?.role?.id ??
          DEFAULT_USER_CONFIGS.tribolab.id_role,
        label:
          initValues?.tribolabConfig?.role?.label ??
          DEFAULT_USER_CONFIGS.tribolab.label,
      },
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={(e) => {
        setIsLoading(true);
        e.preventDefault();
        onSubmit(formData, () => setIsLoading(false));
      }}
    >
      <Box display="flex" flexDirection="column" gap="1rem">
        <FormControl isRequired>
          <FormLabel htmlFor="name">Nombre</FormLabel>
          <Input
            id="name"
            onChange={(e) =>
              setFormData({
                ...formData,
                name: e.target.value,
              })
            }
            value={formData.name}
            autoComplete="off"
            placeholder="Juan Roberto"
          />
        </FormControl>

        <FormControl isRequired isInvalid={!!errorMessage}>
          <FormLabel htmlFor="rut">Rut</FormLabel>
          <Input
            id="rut"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const formattedRut = formatRut(e.target.value);
              setFormData({
                ...formData,
                rut: formattedRut,
              });
            }}
            value={formData.rut}
            autoComplete="off"
            placeholder="11111111-1"
            maxLength={10} // Limitar el tamaño máximo del RUT
          />
          {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="job">Cargo</FormLabel>
          <Input
            id="job"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({
                ...formData,
                job: e.target.value,
              });
            }}
            value={formData.job}
            autoComplete="off"
            placeholder="Ingeniero de Ventas y Servicios"
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="email">Correo</FormLabel>
          <Input
            id="email"
            type="email"
            onChange={(e) =>
              setFormData({
                ...formData,
                email: e.target.value,
              })
            }
            placeholder="email@gmail.com"
            value={formData.email}
            autoComplete="off"
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="cellphone">Celular</FormLabel>
          <Input
            id="cellphone"
            type="number"
            onChange={(e) =>
              setFormData({
                ...formData,
                phone: e.target.value,
              })
            }
            placeholder="911111111"
            value={formData.phone}
            autoComplete="off"
          />
        </FormControl>
        <Box>
          <FormControl>
            <FormLabel htmlFor="zonesSelectForm">Zonas</FormLabel>

            <Select
              id="zonesSelectForm"
              aria-labelledby="zonesSelectForm"
              options={zoneList.map((zone) => {
                return { label: zone.name, value: zone.id };
              })}
              isMulti
              placeholder="Seleccione una o más zonas"
              defaultValue={formData.zones.map((zone) => {
                return { label: zone.name, value: zone.id };
              })}
              onChange={(e) => {
                const selectedZones = e.map((item) => ({
                  id: String(item?.value),
                  name: item?.label,
                }));

                setFormData({
                  ...formData,
                  zones: selectedZones,
                });
              }}
            />
          </FormControl>
        </Box>

        <FormLabel>Configuraciones de plataformas</FormLabel>
        {!isLoadingPlatform && (
          <Accordion allowToggle>
            <PlatformConfigs
              roles={userRolesList.filter(
                (role) =>
                  role.platform?.id ===
                  DEFAULT_USER_CONFIGS.platform.id_platform
              )}
              name="Home"
              onChange={(config) => {
                setFormData({ ...formData, homeConfig: config });
              }}
              selected={formData.homeConfig}
            />
            <PlatformConfigs
              roles={userRolesList.filter(
                (role) =>
                  role.platform?.id ===
                  DEFAULT_USER_CONFIGS.planning.id_platform
              )}
              name="Planning"
              onChange={(config) => {
                setFormData({ ...formData, planningConfig: config });
              }}
              selected={formData.planningConfig}
            />
            <PlatformConfigs
              roles={userRolesList.filter(
                (role) =>
                  role.platform?.id === DEFAULT_USER_CONFIGS.library.id_platform
              )}
              name="Library"
              onChange={(config) => {
                setFormData({ ...formData, libraryConfig: config });
              }}
              selected={formData.libraryConfig}
            />
            <PlatformConfigs
              roles={userRolesList.filter(
                (role) =>
                  role.platform?.id ===
                  DEFAULT_USER_CONFIGS.tribolab.id_platform
              )}
              name="Tribolab"
              onChange={(config) => {
                setFormData({ ...formData, tribolabConfig: config });
              }}
              selected={formData.tribolabConfig}
            />
            <PlatformConfigs
              roles={userRolesList.filter(
                (role) =>
                  role.platform?.id === DEFAULT_USER_CONFIGS.stocks.id_platform
              )}
              name="Stock"
              onChange={(config) => {
                setFormData({ ...formData, stocksConfig: config });
              }}
              selected={formData.stocksConfig}
            />
          </Accordion>
        )}
        <Button variant={"primary"} type="submit" isLoading={isLoading}>
          Aceptar
        </Button>
      </Box>
    </form>
  );
}

function PlatformConfigs({
  roles,
  name,
  onChange,
  selected,
}: {
  roles: UserRole[];
  name: string;
  onChange: (d: PlataformaConfig) => void;
  selected: PlataformaConfig;
}) {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left">
            {name}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Select
          options={roles.map((rol) => {
            return { label: rol.label, value: rol.id };
          })}
          onChange={(e) => {
            onChange({
              ...selected,
              role: {
                id: String(e?.value),
                label:
                  roles.find((item) => item.id === String(e?.value))?.label ??
                  "",
              },
            });
          }}
          placeholder="Seleccione"
          defaultValue={{
            label: selected.role.label,
            value: selected.role.id,
          }}
        />
        <Checkbox
          mt={3}
          isChecked={selected.authorization}
          onChange={(e) =>
            onChange({ ...selected, authorization: e.target.checked })
          }
        >
          Autorización
        </Checkbox>
      </AccordionPanel>
    </AccordionItem>
  );
}
