import { Box, Image } from "@chakra-ui/react";
import BELRAY_LOGO from "assets/images/brands/logo_belray.webp";
import VENTEC_LOGO from "assets/images/brands/logo_ventec.webp";

export default function BrandLogos() {
  return (
    <Box display="flex" gap="1rem" padding="25px" width="100%">
      <Image
        minW="30px"
        maxW="80px"
        src={BELRAY_LOGO}
        alt="Belray Company Logo"
        objectFit="contain"
      />
      <Image
        minW="30px"
        maxW="150px"
        src={VENTEC_LOGO}
        alt="Ventec Company Logo"
        objectFit="contain"
      />
    </Box>
  );
}
