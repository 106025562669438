import * as API from "./gql";
import { EmailReset } from "./types";
import { useMutation } from "@apollo/client";

export const useResetEmailList = () => {
  const [createResetEmail] = useMutation(API.REST_PASSWORD);

  const onCreateResetEmail = async (data: EmailReset): Promise<void> => {
    await createResetEmail({
      variables: {
        rut: data.rut,
        email: data.email,
      },
    });
  };

  return {
    onCreateResetEmail,
  };
};
