import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { SessionStorage } from "@belray-ventec/platform-session";
import { API } from "configs";

const httpLink = createHttpLink({
  uri: API.GESLUB,
});

const authLink = setContext((_, { headers }) => {
  const data = SessionStorage.get();
  return {
    headers: {
      ...headers,
      authorization: data?.authToken ? `Bearer ${data.authToken}` : "",
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});
